import React from "react";
import "./All.css";
import Header from "./components/Header/Header";
import Ourwork from "./components/ourWork/Ourwork";
import Partners from "./components/Partners/Partners";
import About from "./components/About/About";
import Service from "./components/Service/Service";
import Work from "./components/Work/Work";
import Portfolio from "./components/Portfolio/Portfolio";
import Talk from "./components/Talk/Talk";
import Testimonials from "./components/Testimonials/Testimonials";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import Back from "./components/BackToTop/Back";

const App = () => {

  // $(document).ready(function(){
  //   $("#preloader").delay(700).slideUp(700);
  // });

  return (
    <div>
      
      {/* <div id="preloader">
        <span class="loader"></span>
      </div> */}

      <>
        <Header />
        <Ourwork />
        <Partners />
        <About />
        <Service />
        <Work />
        <Portfolio />
        <Testimonials />
        <Contact />
        <Talk />
        <Footer />
        <Back />
      </>
    </div>
  );
};

export default App;
