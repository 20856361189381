import React from "react";
import AVTR1 from "../../assets/avatar1.jpg";
import AVTR2 from "../../assets/avatar2.jpg";
import AVTR3 from "../../assets/avatar3.jpg";
// import AVTR4 from "../../assets/avatar4.jpg";
import "./testimonials.css";
import $ from 'jquery';
import AOS from 'aos';
import 'aos/dist/aos.css';
// import Swiper core and required modules
import { Pagination } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
// // Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

const data = [
  {
    id: "testcard1",
    avatar: AVTR2,
    name: "Tarik Ajvazi",
    review:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
  },

  {
    avatar: AVTR2,
    id: "testcard2",
    name: "Almir Pinduk",
    review:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
  },

  {
    avatar: AVTR3,
    id: "testcard3",
    name: "John Doe",
    review:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
  },

  {
    avatar: AVTR1,
    id: "testcard4",
    name: "Jane Doe",
    review:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
  },
];

const Testimonials = () => {

  $(document).ready(function(){
    // $("#testcard1").mouseenter(function(){
    //   $("#testcard1 h3, #testcard1 p").css("color", "white");
    //   $(".testcard").css("transition", "all 0.3s ease 0s");
    // });
    
    // $("#testcard2").mouseenter(function(){
    //   $("#testcard2 h3, #testcard2 p").css("color", "white");
    //   $(".testcard").css("transition", "all 0.3s ease 0s");
    // });
    
    // $("#testcard3").mouseenter(function(){
    //   $("#testcard3 h3, #testcard3 p").css("color", "white");
    //   $(".testcard").css("transition", "all 0.3s ease 0s");
    // });

    // $("#testcard4").mouseenter(function(){
    //   $("#testcard4 h3, #testcard4 p").css("color", "white");
    //   $(".testcard").css("transition", "all 0.3s ease 0s");
    // });

    // $("#testcard1").mouseleave(function(){
    //   $("#testcard1 h3, #testcard1 p").css("color", "#061322");
    //   $(".testcard").css("transition", "all 0.3s ease 0s");
    // });
    
    // $("#testcard2").mouseleave(function(){
    //   $("#testcard2 h3, #testcard2 p").css("color", "#061322");
    //   $(".testcard").css("transition", "all 0.3s ease 0s");
    // });
    
    // $("#testcard3").mouseleave(function(){
    //   $("#testcard3 h3, #testcard3 p").css("color", "#061322");
    //   $(".testcard").css("transition", "all 0.3s ease 0s");
    // });

    // $("#testcard4").mouseleave(function(){
    //   $("#testcard4 h3, #testcard4 p").css("color", "#061322");
    //   $(".testcard").css("transition", "all 0.3s ease 0s");
    // });
  });

  AOS.init();

  return (
    <section id="testimonials">

        <h5 id="whatsay">WHAT OUR CLIENTS SAY ABOUT US</h5>
        <h1 id="testimonialtext">TESTIMONIALS</h1>

      <div id="firstpartreviews">
        <h1 id="positivereviews">Positive reviews from <br/>200+ happy clients</h1>
        <div id="clientcards">

          <div className="clientcard">
            <h1>150+</h1>
            <h3>Trusted Clients</h3>
          </div>

          <div className="clientcard" id="card2client">
            <h1>50+</h1>
            <h3>Projects Completed</h3>
          </div>

        </div>
      </div>

    <Swiper
        breakpoints={{
        768: {
        width: 1300,
        slidesPerView: 3,
        },
        }}
        
        id="secondpartreviews"
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
      >
        {data.map(({ avatar, name, review, id }, index) => {
          return (
            <SwiperSlide key={index} className="testcard" id={id}>
              <div className="reviewprofile">
                <div className="reviewavatar">
                  <img src={avatar} alt=""/>
                </div>
                <h3 className="profilename">{name}</h3>
              </div>
              <p className="reviewdesc">{review}</p>
            </SwiperSlide>
          );
        })}
      </Swiper>

    </section>
  );
};

export default Testimonials;