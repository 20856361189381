import React from "react";
import "./ourwork.css";
import digital from "../../assets/digital.png";
import AOS from 'aos';
import 'aos/dist/aos.css';

const Ourwork = () => {

  AOS.init();
  return (

      <div id="section1">
      <div id="textdiv" data-aos="fade-down" data-aos-duration="1000">
          <h1>DIGI<span>LAB</span></h1>
          <h2>Create your digital solution with us.</h2>
          <br />
          <div>
            <a href="#contact"><button id="getintouch">Get in touch</button></a>
            <p id="arrow" name="arrow-forward-outline">➜</p>
          </div>
      </div>
      <div id="imagediv" data-aos="fade-up" data-aos-duration="1000">
        <img src={digital} alt="" />
      </div>
      </div>

  );
};

export default Ourwork;
