import React from "react";
import "./back.css";
import backtotop from "../../assets/back.png";
import { useEffect, useState } from "react";

const Back = () => {
    const [backToTopButton, setBackToTopButton] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if(window.scrollY > 100){
                setBackToTopButton(true);
            }

            else{
                setBackToTopButton(false);
            }
        })
    }, [])

    const scrollUp = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }

    return (
        <div>
            {backToTopButton && (
                <button id="backtotop" onClick={scrollUp}>
                    <img src={backtotop} alt="" />
                </button>
            )}

        </div>
    );
}
 
export default Back;