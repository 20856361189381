import React from "react";
import "./contact.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import mailblack from "../../assets/mailblack.png";
import mapblack from "../../assets/mapblack.png";
import callblack from "../../assets/callblack.png";

const Contact = () => {
    
    AOS.init();

    return ( 
        <div id="contact">
            <section id="contactusdiv">
                <div id="firstpartcontact">
                    <form action="" id="contactform">
                        <input type="text" name="firstname" id="firstname" placeholder="First Name"/>
                        <input type="text" name="lastname" id="lastname" placeholder="Last Name"/>
                        <input type="text" name="subject" id="subject" placeholder="Write a subject"/>
                        <textarea name="message" id="message" placeholder="Write a message"></textarea>
                        <button id="sendmessage">Send Message</button>
                    </form>
                </div>

                <div id="secondpartcontact">
                    <h1>Contact Us</h1>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummyLorem Ipsum is simply dummy text of the printing and typesetting industry.</p>

                    <div id="contactlinks">
                        <div id="calllink">
                            <img src={callblack} alt="" />
                            <a href="">+383/45-888-888</a>
                        </div>

                        <div id="maillink">
                            <img src={mailblack} alt="" />
                            <a href="">digilab@gmail.com</a>
                        </div>

                        <div id="maplink">
                            <img src={mapblack} alt="" />
                            <a href="">Rr. Shuaib Spahiu, p.n, 20000, Prizren, Kosova</a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
 
export default Contact