import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import React from "react";
import logo from "../../assets/logo.png";
import "./header.css";
import $ from "jquery";
import close from "../../assets/close.png";

const Header = () => {
  $(document).ready(function(){
    $("#hamburger").click(function(){
      $("#canvas").fadeIn(300);
    });
    $("#closecanvas").click(function(){
      $("#canvas").fadeOut(300);
    });
    $("#linksmobile a").click(function(){
      $("#canvas").fadeOut(300);
    });
  });
  return (
    <div>
      <header>
        <div id="logodiv">
          <img src={logo} alt="" />
          <a href="">
            DIGI<span id="lightblue">LAB</span>
          </a>
        </div>
        <ul>
          <li>
            <a href="#about">About</a>
          </li>
          <li>
            <a href="#servicee">Services</a>
          </li>
          <li>
            <a href="#portfolio">Portfolio</a>
          </li>
          <li>
            <a href="#testimonials">Testimonials</a>
          </li>
        </ul>
        <a href="#contact">
          <button className="contactbutton">Contact</button>
        </a>
      </header>

      <nav>
      <div style={{"display":"flex", "alignItems":"center"}}>
        <img src={logo} id="logomobile" alt="" />
        <a href="#" id="namemob">DIGI<span id="lightblue">LAB</span></a>
      </div>
      <div id="hamburger">
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
      </nav>

      <div id="canvas">
      <div id="canvasheader">
        <div style={{"display":"flex", "alignItems":"center"}}>
          <img src={logo} id="logomobile" alt="" />
          <a href="#" id="namemob">DIGI<span id="lightblue">LAB</span></a>
        </div>
        <img src={close} alt="" id="closecanvas"/>
      </div>
      <div id="linksmobile">
        <a href="#about" id="first">About</a>
        <a href="#servicee">Services</a>
        <a href="#portfolio">Portfolio</a>
        <a href="#testimonials">Testimonials</a>
      </div>
      <div class="icons">
        <a href="tel:+38344233821"><ion-icon name="call-outline"></ion-icon></a>
        <a href="https://www.instagram.com/tarikajvazi.dev/" target="_blank"><ion-icon name="logo-instagram"></ion-icon></a>
        <a href="mailto:tarikajvazi2@gmail.com"><ion-icon name="mail-outline"></ion-icon></a>
      </div>  
      </div>

    </div>
  );
};

export default Header;
