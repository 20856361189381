import React from "react";
import call from "../../assets/call.png";
import cursor from "../../assets/cursor.png";
import facebook from "../../assets/facebook.png";
import insta from "../../assets/insta.png";
import linkedin from "../../assets/linkedin.png";
import logotextt from "../../assets/logotextt.png";
import mail from "../../assets/mail.png";
import "./footer.css";

const Footer = () => {
  return (
    <div class="color-bg">
      <div id="firstpartfooter">
        <img src={logotextt} id="logofooter" />
        <div id="pages">
          <h1 className="h1footer">PAGES</h1>
          <ul>
            <li>
              <a href="#section1">Home</a>
            </li>
            <li>
              <a href="#about">About</a>
            </li>
            <li>
              <a href="#servicee">Services</a>
            </li>
            <li>
              <a href="#portfolio">Portfolio</a>
            </li>
            <li>
              <a href="#testimonials">Testimonials</a>
            </li>
          </ul>
        </div>

        <div id="contactusfooter">
          <h1 className="h1footer">CONTACT US</h1>
          <ul>
            <li>
              <img src={mail} class="iconfooter" />
              <p>digilab@gmail.com</p>
            </li>
            <li>
              <img src={cursor} class="iconfooter" />
              <p>Prizren, Kosova</p>
            </li>
            <li>
              <img src={call} class="iconfooter" />
              <p>+38345999999</p>
            </li>
          </ul>
        </div>

        <div id="followusfooter">
          <h1 className="h1footer">FOLLOW US</h1>
          <ul>
            <li>
              <img src={insta} class="iconfooter" />
              <p>@digilab</p>
            </li>
            <li>
              <img src={facebook} class="iconfooter" />
              <p>@digilab</p>
            </li>
            <li>
              <img src={linkedin} class="iconfooter" />
              <p>@digilab</p>
            </li>
          </ul>
        </div>
      </div>

      <div id="secondpartfooter">
        <p id="copyright">
          ©digilab.com <span>2023 | All rights reserved.</span>
        </p>
      </div>
    </div>
  );
};

export default Footer;
