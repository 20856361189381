import React from "react";
import "./service.css";
import AOS from 'aos';
import 'aos/dist/aos.css';

const Service = () => {

    AOS.init();

    return (
        <div id="servicee">
        
        <h5 id="work">WE WORK WITH YOU, NOT FOR YOU</h5>
        <h1 id="servicetext">SERVICE</h1>

        <div id="service">
            <div className="nendiv">

                <div className="servicecard" data-aos="zoom-in">
                    <h4>WEB DESIGN AND DEVELOPMENT</h4>
                    <div className="lineblue"></div>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has bee</p>
                    <button>Learn More</button>
                </div>

                <div className="servicecard" data-aos="zoom-in" data-aos-delay="100">
                    <h4>WEB DESIGN AND DEVELOPMENT</h4>
                    <div className="lineblue"></div>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has bee</p>
                    <button>Learn More</button>
                </div>

            </div>

            <div className="nendiv">

                <div className="servicecard"  data-aos="zoom-in" data-aos-delay="200">
                    <h4>WEB DESIGN AND DEVELOPMENT</h4>
                    <div className="lineblue"></div>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has bee</p>
                    <button>Learn More</button>
                </div>

                <div className="servicecard"  data-aos="zoom-in" data-aos-delay="300">
                    <h4>WEB DESIGN AND DEVELOPMENT</h4>
                    <div className="lineblue"></div>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has bee</p>
                    <button>Learn More</button>
                </div>

            </div>
        </div>

        </div>
    );
}
 
export default Service;