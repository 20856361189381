import React from "react";
import "./work.css";
import bulb from "../../assets/bulb.png";
import dollar from "../../assets/dollar.png";
import dev from "../../assets/dev.png";
import chart from "../../assets/chart.png";
import AOS from 'aos';
import 'aos/dist/aos.css';

const Work = () => {

    AOS.init();

    return ( 
        <div>
            <h5 id="workflow">WORKFLOW</h5>
            <h1 id="worktext">OUR WORKING PROCESS</h1>

            <div id="workprocess">
                <div id="bulbdiv" className="componentdiv" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500">
                    <div className="center"><img src={bulb} alt="" /></div>
                    <div className="center"><h2>CONCEPT</h2></div>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                </div>

                <div id="dollardiv" className="componentdiv" data-aos="fade-down" data-aos-duration="1000" data-aos-delay="500">
                    <div className="center"><img src={dollar} alt="" /></div>
                    <div className="center"><h2>BUDGET</h2></div>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                </div>

                <div id="devdiv" className="componentdiv" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500">
                    <div className="center"><img src={dev} alt="" /></div>
                    <div className="center"><h2>DEVELOPMENT</h2></div>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                </div>

                <div id="chartdiv" className="componentdiv" data-aos="fade-down" data-aos-duration="1000" data-aos-delay="500">
                    <div className="center"><img src={chart} alt="" /></div>
                    <div className="center"><h2>RESULTS</h2></div>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                </div>
            </div>
        </div>
    );
}
 
export default Work;