import React from "react";
import "./portfolio.css";
import $ from 'jquery';
import mainproject from "../../assets/mainproject.png";
import webproject from "../../assets/webproject.webp";

const Portfolio = () => {
    
    $(document).ready(function(){
        $("#allcategories").css("border-bottom", "2px solid #0088cf");
        $("#allcategories h5").css("color", "#0088cf");
        $("#allprojects").show();
        $("#webdesignprojects, #appdesignprojects, #smmprojects").hide();

        $("#allcategories").click(function(){
            $(this).css("border-bottom", "2px solid #0088cf");
            $("#allcategories h5").css("color", "#0088cf");
            $("#webdesign, #appdesign, #smm").css("border-bottom", "2px solid white");
            $("#webdesign h5, #appdesign h5, #smm h5").css("color", "white");
            
            $("#allprojects").fadeIn();
            $("#webdesignprojects, #appdesignprojects, #smmprojects").hide();
        });

        $("#webdesign").click(function(){
            $(this).css("border-bottom", "2px solid #0088cf");
            $("#webdesign h5").css("color", "#0088cf");
            $("#allcategories, #appdesign, #smm").css("border-bottom", "2px solid white");
            $("#allcategories h5, #appdesign h5, #smm h5").css("color", "white");

            $("#webdesignprojects").fadeIn();
            $("#allprojects, #appdesignprojects, #smmprojects").hide();
        });

        $("#appdesign").click(function(){
            $(this).css("border-bottom", "2px solid #0088cf");
            $("#appdesign h5").css("color", "#0088cf");
            $("#allcategories, #webdesign, #smm").css("border-bottom", "2px solid white");
            $("#allcategories h5, #webdesign h5, #smm h5").css("color", "white");

            $("#appdesignprojects").fadeIn();
            $("#webdesignprojects, #allprojects, #smmprojects").hide();
        });

        $("#smm").click(function(){
            $(this).css("border-bottom", "2px solid #0088cf");
            $("#smm h5").css("color", "#0088cf");
            $("#allcategories, #appdesign, #webdesign").css("border-bottom", "2px solid white");
            $("#allcategories h5, #appdesign h5, #webdesign h5").css("color", "white");

            $("#smmprojects").fadeIn();
            $("#webdesignprojects, #appdesignprojects, #allprojects").hide();
        });
    });

    return (
        <div id="portfolio">

        <h5 id="takealook">TAKE A LOOK AT OUR PROJECTS</h5>
        <h1 id="portfoliotext">PORTFOLIO</h1>

        <div id="navportfolio">
            <div className="category" id="allcategories">
                <h5>All Categories</h5>
            </div>
            <div className="category" id="webdesign">
                <h5>Web Design</h5>
            </div>
            <div className="category" id="appdesign">
                <h5>App design</h5>
            </div>
            <div className="category" id="smm">
                <h5>Social Media Marketing</h5> 
            </div>
        </div>

        <div className="projects" id="allprojects">
            <div id="mainproject">
                <img src={mainproject} alt="" />
                <h5>Tarik Ajvazi</h5>
            </div>
            <div className="sideprojects">
                <img src={mainproject} alt="" className="side1"/>
                <img src={mainproject} alt="" className="side2"/>
            </div>
        </div>

        <div id="webdesignprojects" className="projects">
            <div className="sideprojects">
                <img src={webproject} alt="" className="side1"/>
                <img src={webproject} alt="" className="side2"/>
            </div>
            <div className="sideprojects">
                <img src={webproject} alt="" className="side1"/>
                <img src={webproject} alt="" className="side2"/>
            </div>
        </div>

        <div id="appdesignprojects" className="projects">
            <div className="sideprojects">
                <img src={mainproject} alt="" className="side1"/>
                <img src={mainproject} alt="" className="side2"/>
            </div>
            <div className="sideprojects">
                <img src={mainproject} alt="" className="side1"/>
                <img src={mainproject} alt="" className="side2"/>
            </div>
        </div>

        <div id="smmprojects" className="projects">
            <div className="sideprojects">
                <img src={mainproject} alt="" className="side1"/>
                <img src={mainproject} alt="" className="side2"/>
            </div>
            <div className="sideprojects">
                <img src={mainproject} alt="" className="side1"/>
                <img src={mainproject} alt="" className="side2"/>
            </div>
        </div>
        

        </div>
    );
}
 
export default Portfolio;