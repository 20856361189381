import React from "react";
import "./about.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import $ from "jquery";

const About = () => {

    AOS.init();

    $(document).ready(function(){
        //card1

        $("#card1").mouseenter(function(){
            $("#card1 h1").css("color", "white");
            $(this).css({"background-color":"#0088cf", "box-shadow":"0px 0px 30px #0088cf"});
        });

        $("#card1").mouseleave(function(){
            $("#card1 h1").css("color", "#0088cf");
            $(this).css({"background-color":"transparent", "box-shadow":"0px 0px 0px", "transition":"all 0.3s ease 0s"});
        });

        //card2

        $("#card2").mouseenter(function(){
            $("#card2 h1").css("color", "white");
            $(this).css({"background-color":"#0088cf", "box-shadow":"0px 0px 30px #0088cf"});
        });

        $("#card2").mouseleave(function(){
            $("#card2 h1").css("color", "#0088cf");
            $(this).css({"background-color":"transparent", "box-shadow":"0px 0px 0px", "transition":"all 0.3s ease 0s"});
        });

        //card3

        $("#card3").mouseenter(function(){
            $("#card3 h1").css("color", "white");
            $(this).css({"background-color":"#0088cf", "box-shadow":"0px 0px 30px #0088cf"});
        });

        $("#card3").mouseleave(function(){
            $("#card3 h1").css("color", "#0088cf");
            $(this).css({"background-color":"transparent", "box-shadow":"0px 0px 0px", "transition":"all 0.3s ease 0s"});
        });

        //card4

        $("#card4").mouseenter(function(){
            $("#card4 h1").css("color", "white");
            $(this).css({"background-color":"#0088cf", "box-shadow":"0px 0px 30px #0088cf"});
        });

        $("#card4").mouseleave(function(){
            $("#card4 h1").css("color", "#0088cf");
            $(this).css({"background-color":"transparent", "box-shadow":"0px 0px 0px", "transition":"all 0.3s ease 0s"});
        });
    });

    return(

        <div id="about">
            <div id="textside">
                <h1>About our <span id="white">Company</span></h1>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                <button data-aos="fade-right">Learn More</button>
            </div>

            <div id="imageside">
                <div className="lines line1">
                    <div className="cards" id="card1" data-aos="zoom-in">
                        <h1>10</h1>
                        <h4>Working Prototypes</h4>
                        <h6>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h6>
                    </div>
                    <div className="cards" id="card2" data-aos="zoom-in" data-aos-delay="100">
                        <h1>300</h1>
                        <h4>Lorem Ipsum</h4>
                        <h6>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h6>
                    </div>
                </div>
                <div className="lines line2">
                    <div className="cards" id="card3" data-aos="zoom-in">
                        <h1>100+</h1>
                        <h4>Trusted Clients</h4>
                        <h6>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h6>
                    </div>
                    <div className="cards" id="card4" data-aos="zoom-in" data-aos-delay="100">
                        <h1>100%</h1>
                        <h4>Success Rate</h4>
                        <h6>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h6>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default About;