import React from "react";
import "./talk.css";
import arrow from "../../assets/arrow.png";
import AOS from 'aos';
import 'aos/dist/aos.css';

const Talk = () => {
    
    AOS.init();

    return ( 
        <div>
            
            <div id="maintalk">
                <div id="talktext">
                    <a href="#contact" class="nounderline"><h2>HAVE A PROJECT <span id="question">?</span></h2></a>
                    <a href="#contact" class="nounderline"><h1>LET'S TALK <span id="dot">.</span></h1></a>
                </div>
                <a href="#contact"><img src={arrow} alt="" id="arrowsecond"/></a>
            </div>

        </div>
    );
}
 
export default Talk