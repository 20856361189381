import React from "react";
import "./partners.css";
import profile from "../../assets/profile.png";
import AOS from 'aos';
import 'aos/dist/aos.css';

const Partners = () => {

    AOS.init();

    return(
        <div id="section2">
            <div id="trusted">
                <h4>TRUSTED BY</h4>
                <h1>BRANDS ALL OVER THE WORLD</h1>
            </div>

            <div id="icons">
                <div className="profile" data-aos="zoom-in">
                    <div className="center"><img src={profile} alt="" /></div>
                    <h4>SMART GALAXY</h4>
                    <h6>Education</h6>
                </div>
                <div className="profile" data-aos="zoom-in" data-aos-delay="100">
                    <div className="center"><img src={profile} alt="" /></div>
                    <h4>SMART GALAXY</h4>
                    <h6>Education</h6>
                </div>
                <div className="profile" data-aos="zoom-in" data-aos-delay="200">
                    <div className="center"><img src={profile} alt="" /></div>
                    <h4>SMART GALAXY</h4>
                    <h6>Education</h6>
                </div>
            </div>
        </div>
    );
};

export default Partners;